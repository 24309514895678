html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  color: #242424;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.img {
  vertical-align: middle;
}

/* input  */
.MuiOutlinedInput-root fieldset {
  border: none;
}

.MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
}
.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 6px 10px;
  font-size: 14px;
}

.login-input .MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 16px;
}
/* navbar menu item */
.MuiChip-root.MuiChip-filled {
  padding: 10px;
  background: transparent;
  cursor: pointer;
}

.MuiChip-root.MuiChip-filled:hover {
  background-color: #ffeeec;
}
.MuiChip-label {
  font-weight: bold;
}

.MuiChip-root.MuiChip-filled:hover .MuiChip-label {
  color: #f1776c;
}
.MuiButtonBase-root.MuiMenuItem-root:hover {
  background: transparent;
}
.MuiChip-root.avartar-chip:hover {
  background-color: transparent;
}
span.MuiChip-label.MuiChip-label span {
  color: #969696;
  font-weight: normal;
}

/* input  */
.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error.input-readonly {
  background-color: #ebebeb;
}
/* input error */
.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error {
  border: 1px solid #f1776c;
  border-radius: 10px;
}

/* Dialog */
.MuiTypography-h6.MuiDialogTitle-root {
  color: #359db4;
  font-weight: bold;
}
.MuiDialog-container .MuiPaper-root {
  width: auto;
  padding: 16px;
  max-width: 800px;
}

/* DatePicker */
/*.table .date-picker .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  background: #fff;
}*/
.table .date-picker.MuiFormControl-root.MuiTextField-root {
  background: #ffffff;
}
.date-picker.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  background: #f6fbff;
}
.date-picker.MuiFormControl-root.MuiTextField-root input:read-only {
  background-color: #ebebeb;
}
.date-picker.error.MuiFormControl-root.MuiTextField-root {
  border: 1px solid #f1776c;
  border-radius: 10px;
}
.date-picker.MuiFormControl-root.MuiTextField-root label {
  font-size: 14px;
}
.date-picker.MuiFormControl-root input {
  font-size: 14px;
  color: #7d7c7c;
}
.date-picker.MuiFormControl-root input::placeholder {
  visibility: hidden;
}
.date-picker.MuiFormControl-root button {
  color: #359db4;
}

.date-picker .MuiFormControl-root .MuiInputLabel-root {
  /*display: none;*/
}

/* dropdown */
.table .dropdown .MuiOutlinedInput-root {
  color: #7d7c7c;
  background-color: #ffffff;
}
.dropdown .MuiOutlinedInput-root {
  color: #7d7c7c;
  background-color: #f6fbff;
}
.MuiMenuItem-root.MuiMenuItem-gutters {
  font-size: 14px;
}
.dropdown.MuiFormControl-root .MuiInputBase-root::before,
.inputfile.MuiFormControl-root .MuiInputBase-root::before,
.inputfile.MuiFormControl-root .MuiInputBase-root:hover::before {
  border-bottom: none;
}
.dropdown .MuiInputBase-root.MuiInputBase-colorPrimary {
  font-size: 14px;
}
.dropdown-li.MuiMenuItem-root {
  font-size: 14px;
}

/* inputfile */

.inputfile .MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error {
  border: none;
}

.MuiFormControl-root button.inputfile-button {
  padding: 6px 10px;
  min-width: 100px;
  width: 30%;
}

/* rfq-info */
.rfq-info-wrapper .MuiGrid-root.MuiGrid-container {
  width: 800px;
  margin: 0px;
}

/* rfq-textarea */
textarea.rfq-textarea {
  width: 100%;
  padding: 6px 10px;
  border-radius: 10px;
  color: #7d7c7c;
  background: #f6fbff;
  border: none;
  outline: none;
  resize: none;
}
textarea.input-readonly {
  /*background-color: #ebebeb;*/
  background-color: #f4f8fb;
  color: #359db4;
}

.email-template-content {
  width: 100%;
  background-color: #f4f8fb;
  padding: 6px 10px;
  border-radius: 10px;
  color: #7d7c7c;
  font-size: 14px;
}
